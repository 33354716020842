<template>
	<div class="container-modal form-modal" id="step3">
        <div v-if="$resize && $mq.above(601)" class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		
		<div class="container-holder">
			<div class="info-content">
				<div class="header-modal">
					<div class="title">{{ $t('register.title_step_3') }}</div>
				</div>
				<div class="container-content-modal" v-if='loaded'>
					<div class="form-section-register">
						<div class="input-login-group-tbf w-tbf-100">
							<div class="subscription-box custom" v-for="subPackage,index in packagesList" v-bind:class="{active: subPackage.id == packageActive.id, inactive: packageActive.id && subPackage.id != packageActive.id, first: index == 0, 'error-selected': errorSelectPlan}" @click="selectPlan(subPackage)">
								<div class="top-section">
									<p class="first">
										{{ subPackage.name }}
										<span>{{ subPackage.short_description }}</span>
									</p>
									<div class="second">
										<div class="price">
											{{ user.newPrices.find((el) => el.package_id == subPackage.id) ? user.newPrices.find((el) => el.package_id == subPackage.id).price + ' ' + subPackage.euro_prefix : subPackage.price_in_euro + ' ' + subPackage.euro_prefix }}
										</div>
										<span class="old-price" v-if="user.newPrices.find((el) => el.package_id == subPackage.id)">{{ subPackage.price_in_euro }} EUR / luna</span>
										<span v-else>{{ $t('subscription.monthly') }}</span>
									</div>
								</div>
								<div class="third-section">
									<div class="box mobile">
										<div class="icon"><icon-people /></div>
										<span v-if="subPackage.user_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.user_limit }} <br>{{ $t('subscription.upgrade.employees') }}</span>
										<span v-else>{{ $t('subscription.upgrade.employees-b') }} <br>{{ $t('subscription.upgrade.unlimited') }}</span>
									</div>
									<div class="box mobile">
										<div class="icon icon-obj"><icon-invoice /></div>
										<span v-if="subPackage.objective_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.objective_limit }} <br>{{ $t('subscription.upgrade.objectives') }}</span>
										<span v-else>{{ $t('subscription.upgrade.objectives-b') }} <br>{{ $t('subscription.upgrade.unlimiteds') }}</span>
									</div>
									<div class="box mobile">
										<div class="icon"><icon-settings /></div>
										<span v-if="subPackage.results_limit">{{ $t('subscription.upgrade.up-to') }} {{ subPackage.results_limit }} <br>{{ $t('subscription.upgrade.key-results') }}</span>
										<span v-else>{{ $t('subscription.upgrade.key-results-b') }} <br> {{ $t('subscription.upgrade.unlimiteds') }}</span>
									</div>
								</div>
							</div>
						</div>	

						<div class="input-login-group-tbf w-tbf-100">
							<p class="get-quote" v-html="$t('subscription.get_quote')"></p>
						</div>

					</div>
					<div class="submit-form">
						<button class="btn-tbf blue center" @click="openConfirmation">
							<div class="loader"></div>
							<div class="text">{{$t('register.next_step')}}</div>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="submodal-container active" v-if="subModalShow">
			<div class="overlay-submodal"></div>

			<div class="submodal-tbf active">
				<div class="container-content-modal">
					<div class="header-modal">
						<div class="title">{{ $t('subscription.confirm_subscription_title') }}</div>
						<div class="actions">
							<div class="btn-default-tbf close-btn" @click="closeModalConfirmation"><icon-close class="icon-close" /></div>
						</div>
					</div>
					<div class="summary">
						<div class="summary-box">
							{{ $t('subscription.confirm_subscription_summary', { package: packageActive.name, price: 
								user.newPrices.find((el) => el.package_id == packageActive.id) ? (user.newPrices.find((el) => el.package_id == packageActive.id).price + ' ' + packageActive.euro_prefix) : (packageActive.price_in_euro + ' ' + packageActive.euro_prefix)}) }}
						</div>

						<div class="submit-form">
							<button id="buttonNextStep" class="btn-tbf blue center" @click="goNextStep">
								<div class="loader"></div>
								<div class="text">{{ $t('subscription.confirm') }}</div>
							</button>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';

	import IconClose from '../Icons/Close'
	import IconPeople from '../Icons/People'
	import IconInvoice from '../Icons/Invoice'
	import IconSettings from '../Icons/Settings'

	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				packageActive: {},
				packagesList: {},
				subModalShow: false,
				loaded: false,
				errorSelectPlan: false
			}
		},
		props:{
			user: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconInvoice,
			IconSettings,
			Lottie
		},
		async mounted() {
			await this.getPackages()
		},
		methods: {
			async getPackages(){
				await axios.get('packages').then(({data}) => {
					this.packagesList = data.data.reverse();

					if(this.$route.query.token){
						this.packageActive = this.packagesList.find(el => el.token == this.$route.query.token)
						this.user.packageActive = this.packageActive
					}else{
						this.packageActive = {};
					}

					this.loaded = true;
					this.logoAnim();
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
			},
			openConfirmation(){
				//temporar
				this.errorSelectPlan = false

				if(this.packageActive.id){
					this.subModalShow = true;
				}else{
					this.errorSelectPlan = true
				}
			},
			goNextStep(e){
				var buttonName = 'buttonNextStep'
                var btnSubmit = document.getElementById(buttonName);
                var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
                var btnSubmitText = document.querySelector(`#${buttonName} .text`)

                btnSubmit.disabled = true
                btnSubmit.classList.add('loading')
                btnSubmitLoader.classList.add('onProgress')
                btnSubmitText.innerHTML = this.$t('btn-submit.loading')

                btnSubmitLoader.classList.add('finish')
                setTimeout(()=>{
                	btnSubmitText.innerHTML = this.$t('btn-submit.success')
                	btnSubmit.classList.add('completed')
                	btnSubmitLoader.classList.remove('onProgress', 'finish')
                	btnSubmit.classList.remove('loading')
                	setTimeout(()=>{
                		btnSubmit.classList.remove('completed')
                		btnSubmitText.innerHTML = this.$t('subscription.confirm')
                		this.$emit("next_step", {user: this.user, next_step: 4});
                	}, 1000)
                }, 300)
			},

			closeModal(){
				this.$router.push("/login");
			},
			closeModalConfirmation() {
				this.subModalShow = false;
			},
			selectPlan(packageItem){
				this.errorSelectPlan = false
				this.packageActive = packageItem;
				this.user.packageActive = packageItem;
			},
			displayModal(){
				this.subModalShow = true;
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			}
		}
	}
</script>