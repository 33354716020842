<template>
	<div class="active modal-register">
		<transition name="slide" mode="out-in">
			<register-step-1 v-if="step_active == 1 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-2 v-if="step_active == 2 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-3 v-if="step_active == 3 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-4 v-if="step_active == 4 && $route.name == 'register'" :user="user" @next_step="next_step"/>
			<register-step-5 v-if="step_active == 5 && $route.name == 'payment'" @next_step="next_step"/>
		</transition>
	</div>
</template>

<script>
import RegisterStep1 from './RegisterStep1'
import RegisterStep2 from './RegisterStep2'
import RegisterStep3 from './RegisterStep3'
import RegisterStep4 from './RegisterStep4'
import RegisterStep5 from './RegisterStep5'

export default {
	data(){
		return {
			step_active: this.$route.name == 'payment' ? 3 : 1,
			user: {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				password: '',
				language: '',
				has_company: '',
				company_name: '',
				company_cui: '',
				complet_name: '',
				cnp: '',
				terms_and_conditions: '',
				country: ''
			}
		}
	},
	components: {
		RegisterStep1,
		RegisterStep2,
		RegisterStep3,
		RegisterStep4,
		RegisterStep5
	},
	watch: {
		$route (to, from){
			if(this.$route.name == 'payment'){
				this.step_active = 5
			}
		}
	},
	async mounted() {
		if(this.$route.name == 'payment'){
			this.step_active = 5
		}
	},
	methods: {
		viewGame(){
			$('.bird-login').removeClass('active');
			setTimeout(() => {
				this.view_game = true;
				setTimeout(() => {
					game_start();
				},0)
			},300)
		},
		next_step(value){
			this.user = value.user ? value.user : this.user

			if(value.action == 'finish_redirect'){
				this.$router.push('/')
			}else{
				this.step_active = value.next_step ? value.next_step : this.step_active
			}
		}
	}
}
</script>